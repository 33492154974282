<script lang="ts">
    import * as prismic from '@prismicio/client';
    import { PrismicLink, PrismicImage, PrismicRichText } from '@prismicio/svelte';
	import PrismicVideo from './PrismicVideo.svelte';
	import { onMount } from 'svelte';
    import type { LinkToMediaField, FilledImageFieldImage } from '@prismicio/types';

    export let index = 0;
    export let title;
    export let description: prismic.RichTextField | null = null;
    export let project;
    export let video;
    export let image;
    export let thumbnail: LinkToMediaField | null = null;
    export let baseClass;

    let rerenderedThumbnail: FilledImageFieldImage | null = null;
    let backgroundColor: string = '';
    let foregroundColor: string = '';

    onMount(() => {
        if (prismic.isFilled.linkToMedia(thumbnail) && thumbnail.kind == 'image') {
            let width: number = thumbnail.width ? parseInt(thumbnail.width) : 800;
            let height: number = (thumbnail.height) ? parseInt(thumbnail.height) : 800;

            rerenderedThumbnail = {
                id: thumbnail.id,
                edit: {
                    background: 'transparent',
                    x: 0,
                    y: 0,
                    zoom: 1
                },
                copyright: null,
                url: thumbnail.url,
                alt: thumbnail.name || '',
                dimensions: {
                    width: width,
                    height: height,
                }
            };
        }

        backgroundColor = (project.data && project.data.background_color) ? project.data.background_color : '#000000';
        foregroundColor = (project.data && project.data.foreground_color) ? project.data.foreground_color : '#FFFFFF';
    });
</script>

<PrismicLink field={project} id="project-link-{index}" class="{baseClass}">
    <div class="{baseClass}--media-container card-image-placeholder">
        <div class="card-image-placeholder__wrapper">
            {#if prismic.isFilled.linkToMedia(thumbnail) && thumbnail.kind == 'video'}
                <PrismicVideo
                    field={thumbnail}
                    poster={image}
                    classes="{baseClass}--media"
                    autoplay
                    loop
                    muted
                />
            {:else if prismic.isFilled.linkToMedia(thumbnail) && thumbnail.kind == 'image'}
                <PrismicImage
                    field={rerenderedThumbnail}
                    class="{baseClass}--media"
                    loading="lazy"
                />
            {:else if prismic.isFilled.linkToMedia(video)}
                <PrismicVideo
                    field={video}
                    poster={image}
                    classes="{baseClass}--media"
                    autoplay
                    loop
                    muted
                />
            {:else if prismic.isFilled.image(image)}
                <PrismicImage
                    field={image}
                    class="{baseClass}--media"
                    loading="lazy"
                />
            {/if}

            {#if description !== null && prismic.isFilled.richText(description)}
                <div class="{baseClass}--description" style="--projectBackground:{backgroundColor};--projectForeground:{foregroundColor};">
                    <PrismicRichText field={description} />
                </div>
            {/if}
        </div>
    </div>

    <div class="{baseClass}--title">{title}</div>
</PrismicLink>
