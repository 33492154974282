<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { PrismicRichText } from '@prismicio/svelte';

	export let slice: Content.QuoteSlice;
	let quoteBackground = slice.primary.background_color ?? '--bs-body-bg';
	let quoteForeground = slice.primary.foreground_color ?? '--bs-body-color';
</script>

<section class="quote" data-slice-type={slice.slice_type} data-slice-variation={slice.variation} style="--quoteBackground:{quoteBackground};--quoteForeground:{quoteForeground};">
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-9">
				<blockquote>
					<div class="quote__content">
						<PrismicRichText field={slice.primary.content} />
					</div>
					<footer class="quote__footer">{slice.primary.credit}</footer>
				</blockquote>
			</div>
		</div>
	</div>
</section>
