<script lang="ts">
	import * as prismic from '@prismicio/client';
	import PrismicVideo from '$lib/components/PrismicVideo.svelte';
	import type { Content } from '@prismicio/client';
	import { PrismicImage, PrismicLink } from '@prismicio/svelte';
	import { afterUpdate } from 'svelte';
	import { Slideshow } from '$lib/helpers/app.slideshow';

	export let slice: Content.ProjectSlideshowSlice;

	const projects = (slice.primary.slides.length) ? slice.primary.slides : false;
	let slideshowElement: HTMLElement;

    afterUpdate(() => {
        if (slideshowElement) {
            new Slideshow(slideshowElement);
        }
    });
</script>

<section class="project-slideshow" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div
		data-mobile="1"
		data-desktop="1"
		data-hd="1"
		data-autoplay="1"
		data-speed="8"
		data-arrows="0"
		data-counter="1"
		bind:this={slideshowElement}
	>
		{#if projects}
			<div id="Slider-{slice.id}"  class="component-slideshow__list project-slideshow__list">
				{#each projects as item,index}
					<PrismicLink field={item.project} id="Slide-{slice.id}-{index}" class="component-slideshow__slide project-slideshow__item" data-hold={item.slide_hold ?? 8} aria-label="Read more about {item.title}">
						<div class="project-slideshow__item--background">
							{#if prismic.isFilled.linkToMedia(item.video)}
								<PrismicVideo
									field={item.video}
									poster={item.image}
									classes="project-slideshow__item--video"
									autoplay
									loop
									muted
								/>
							{/if}

							{#if prismic.isFilled.image(item.image)}
								<PrismicImage
									field={item.image}
									class="project-slideshow__item--image"
									loading="lazy"
								/>

								{@html `
									<script type="application/ld+json">
										{
											"@context": "https://schema.org/",
											"@type": "ImageObject",
											"contentUrl": "${item.image.url}",
											"creditText": "${item.image.alt}",
											"creator": {
												"@type": "Organization",
												"name": "Limegreentangerine",
												"url": "https://www.limegreentangerine.co.uk"
											},
											"copyrightNotice": "&copy; Limegreentangerine"
										}
									</script>
								`}
							{/if}
						</div>

						<div class="project-slideshow__item--content">
							<div class="container h-100">
								<div class="row h-100 align-items-end">
									<div class="col-9 col-lg-6">
										{#if item.title}
											<h2 class="project-slideshow__item--title">{item.title}</h2>
										{/if}

										{#if item.tagline}
											<p class="project-slideshow__item--tagline">{item.tagline}</p>
										{/if}
									</div>
								</div>
							</div>
						</div>
					</PrismicLink>
				{/each}
			</div>

			<div class="component-slideshow__button--container">
				<button type="button" name="previous" class="component-slideshow__button prev" aria-label="Slideshow Previous">
					Prev
				</button>

				<button type="button" name="next" class="component-slideshow__button next" aria-label="Slideshow Next">
					Next
				</button>
			</div>

			<div class="component-slideshow__counter--container">
				<div class="component-slideshow__counter--items">
					<span id="CurrentSlide-{slice.id}">0</span>
					<span>/</span>
					<span id="TotalSlides-{slice.id}">0</span>
				</div>
			</div>
		{/if}
	</div>
</section>
