<script lang="ts">
	import type { Content } from '@prismicio/client';

	export let slice: Content.SpacerSlice;
	let mobileSize = (slice.variation == 'custom' && slice.primary.mobile) ? slice.primary.mobile : 0;
	let desktopSize = (slice.variation == 'custom' && slice.primary.desktop) ? slice.primary.desktop : 0;
	let hdSize = (slice.variation == 'custom' && slice.primary.hidpi) ? slice.primary.hidpi : 0;
</script>

<section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	{#if slice.variation == 'custom'}
		<div class="d-block d-lg-none" style="height:{mobileSize}px;"></div>
		<div class="d-none d-lg-block" style="height:{desktopSize}px;"></div>
	{:else if slice.variation == 'gutter'}
		<div class="g-3" style="height:var(--bs-gutter-y);"></div>
	{:else if slice.variation == 'medium'}
		<div class="d-block d-lg-none" style="height:40px;"></div>
		<div class="d-none d-lg-block" style="height:80px;"></div>
	{:else if slice.variation == 'large'}
		<div class="d-block d-lg-none" style="height:60px;"></div>
		<div class="d-none d-lg-block" style="height:120px;"></div>
	{:else}
		<div class="d-block d-lg-none" style="height:50px;"></div>
		<div class="d-none d-lg-block" style="height:120px;"></div>
	{/if}
</section>
