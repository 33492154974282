<script lang="ts">
	import * as prismic from '@prismicio/client';
	import PrismicVideo from '$lib/components/PrismicVideo.svelte';
	import type { Content } from '@prismicio/client';

	export let slice: Content.TiledVideoSlice;
	let columnNumber = (slice.primary.number_of_columns) ? (12 / slice.primary.number_of_columns) : 6;
	let columnGap = (slice.primary.column_gap) ? slice.primary.column_gap : 3;
</script>

<section class="tiled-videos" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	{#if slice.variation !== 'fullWidth'}
		<div class="container">
			{#if slice.primary.title}
				<h4 class="tiled-videos__title">{slice.primary.title}</h4>
			{/if}

			<div class="row g-{columnGap}">
				{#each slice.primary.videos as item}
					<div class="col-12 col-lg-{columnNumber}">
						<div class="tiled-videos__video--container">
							{#if prismic.isFilled.linkToMedia(item.video)}
								<PrismicVideo
									field={item.video}
									poster
									classes="tiled-videos__video"
									autoplay
									loop
									muted
								/>
							{/if}
						</div>
					</div>
				{/each}
			</div>
		</div>
	{:else}
		{#if slice.primary.title}
			<h4 class="tiled-videos__title">{slice.primary.title}</h4>
		{/if}
		<div class="row g-{slice.primary.column_gap}">
			{#each slice.primary.videos as item}
				<div class="col-12 col-lg-{columnNumber}">
					<div class="tiled-videos__video--container">
						{#if prismic.isFilled.linkToMedia(item.video)}
							<PrismicVideo
								field={item.video}
								poster
								classes="tiled-videos__video"
								autoplay
								loop
								muted
							/>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	{/if}
</section>
