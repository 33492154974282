<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { PrismicRichText } from '@prismicio/svelte';
	import Label from './Label.svelte';

	export let slice: Content.RichTextSlice;
	const columnRule = (slice.variation == 'columns' && slice.primary.column_rule) ? 'solid 1px var(--bs-border-color)' : '';
	const gridArrangement = (slice.variation == 'grid' && slice.primary.alignment) ? slice.primary.alignment.toLowerCase() : false;
	let gridArrangementClass = '';
	if (gridArrangement) {
		switch(gridArrangement) {
			case 'right':
				gridArrangementClass = 'justify-content-end';
				break;
			case 'center':
				gridArrangementClass = 'justify-content-center';
				break;
			case 'left':
			default:
				gridArrangementClass = 'justify-content-start';
				break;
		}
	}
</script>

<section class="rich-text" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	{#if slice.variation == 'default'}
		<PrismicRichText
			field={slice.primary.content}
			components={{
				label: Label
			}}
		/>
	{:else}
		<div class="container">
			{#if slice.variation == 'grid'}
				<div class="row {gridArrangementClass}">
					<div class="col-12 col-lg-{slice.primary.grid_columns}">
						<PrismicRichText
							field={slice.primary.content}
							components={{
								label: Label
							}}
						/>
					</div>
				</div>
			{:else if slice.variation == 'columns'}
				<div class="rich-text__columns" style="--numberOfColumns:{slice.primary.columns};--columnSpacing:{slice.primary.column_spacing}px;--columnRule:{columnRule};">
					<PrismicRichText
						field={slice.primary.content}
						components={{
							label: Label
						}}
					/>
				</div>
			{:else}
				<PrismicRichText
					field={slice.primary.content}
					components={{
						label: Label
					}}
				/>
			{/if}
		</div>
	{/if}
</section>
