<script lang="ts">
	import ProjectCard from '$lib/components/ProjectCard.svelte';
	import { createClient } from '$lib/prismicio';
	import type { Content } from '@prismicio/client';
	import { onMount } from 'svelte';
	import type { WorkDocument } from '../../../prismicio-types';
	import Loader from '$lib/components/Loader.svelte';

	export let slice: Content.RelatedProjectsSlice;

	let maxColumns = (slice.primary.max_columns) ? slice.primary.max_columns : 3;
	let relatedProjects: WorkDocument[] | null = null;

	onMount(async () => {
		const relatedPostIds = slice.primary.projects.map((item) => item.project.id);
		const client = createClient();
		const response = await client.getByIDs(relatedPostIds);

		if (response) {
			relatedProjects = response.results as WorkDocument[];
		}
	});

</script>

<section class="related-projects" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		{#if slice.primary.title}
			<div class="row">
				<div class="col-12">
					<h3 class="related-projects__title">{slice.primary.title}</h3>
				</div>
			</div>
		{/if}

		{#if relatedProjects}
			<div class="row g-3">
				{#each relatedProjects as item,index}
					<div class="col-12 col-lg mb-3">
						<ProjectCard
							index={index}
							project={item}
							title={item.data.title}
							description={item.data.introduction}
							thumbnail={item.data.menu_thumbnail}
							image={item.data.banner_image}
							video={item.data.banner_video}
							baseClass="featured-projects__item"
						/>
					</div>

					{#if index > 0 && index % maxColumns === 0}
						<div class="clear"></div>
					{/if}
				{/each}
			</div>
		{:else}
			<div class="d-flex justify-content-center">
				<Loader />
			</div>
		{/if}
	</div>
</section>
