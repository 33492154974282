<script lang="ts">
	import PrismicVideo from '$lib/components/PrismicVideo.svelte';
	import * as prismic from '@prismicio/client';
	import type { Content } from '@prismicio/client';
	import { PrismicImage, PrismicRichText } from '@prismicio/svelte';

	export let slice: Content.MobileScreensSlice;

	const mobileScreens = (slice.primary.mobile_screens.length) ? slice.primary.mobile_screens : false;
	const contentOrder = (slice.primary.content_arrangement == 'Image > Content') ? 'flex-lg-row-reverse' : '';
	const contentWidth = slice.primary.content_width || 6;
	const imageWidth = slice.primary.image_width || 6;
</script>

<section class="mobile-screens" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		<div class="row justify-content-{slice.primary.content_spacing.toLowerCase()} align-items-stretch {contentOrder}">
			<div class="col-12 col-lg-{contentWidth}">
				<div class="position-sticky">
					{#if slice.primary.subtitle}
						<span class="mobile-screens__subtitle">{slice.primary.subtitle}</span>
					{/if}

					{#if slice.primary.title}
						<h3 class="mobile-screens__title">{slice.primary.title}</h3>
					{/if}

					{#if prismic.isFilled.richText(slice.primary.content)}
						<div class="mobile-screens__content">
							<PrismicRichText field={slice.primary.content} />
						</div>
					{/if}
				</div>
			</div>

			<div class="col-12 col-lg-{imageWidth} mobile-screens__image-column">
				{#if mobileScreens}
					<div class="row g-3 g-lg-5">
						{#each mobileScreens as item,index}
							<div class="col-6">
								<div class="mobile-screens__screen" class:offset={index % 2}>
									<div class="spinner-grow spinner-grow-sm text-dark" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>

									{#if prismic.isFilled.linkToMedia(item.video)}
										<PrismicVideo
											field={item.video}
											poster={item.screen}
											classes="mobile-screens__screen--media"
											autoplay
											loop
											muted
										/>
									{:else if prismic.isFilled.image(item.screen)}
										<PrismicImage
											field={item.screen}
											class="mobile-screens__screen--media"
											loading="lazy"
										/>

										{@html `
											<script type="application/ld+json">
												{
													"@context": "https://schema.org/",
													"@type": "ImageObject",
													"contentUrl": "${item.screen.url}",
													"creditText": "${item.screen.alt}",
													"creator": {
														"@type": "Organization",
														"name": "Limegreentangerine",
														"url": "https://www.limegreentangerine.co.uk"
													},
													"copyrightNotice": "&copy; Limegreentangerine"
												}
											</script>
										`}
									{/if}
								</div>
							</div>
						{/each}
					</div>
				{/if}
			</div>
		</div>
	</div>
</section>
