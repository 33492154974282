export function PlayVideoOnScroll(video: HTMLElement) {
    const videoElement = video as HTMLVideoElement;

    if (!isVideoPlaying(videoElement)) {
        videoElement.play().then(() => {
            const parent = videoElement.parentElement;
            parent?.classList.add('lgt-video-playing');
        });
    }
}

export function PauseVideoOnScroll(video: HTMLElement) {
    const videoElement = video as HTMLVideoElement;
    const parent = videoElement.parentElement;
    parent?.classList.remove('lgt-video-playing');

    if (isVideoPlaying(videoElement)) {
        videoElement.pause();
    }
}

export function handleVideoPlay(event: MouseEvent) {
    const element = event.target as HTMLElement;
    const videoId = element.dataset.target;
    if (videoId) {
        const videoElement = document.getElementById(videoId) as HTMLVideoElement;
        if (videoElement) {
            PlayButton(videoElement);
        }
    }
}

export function PlayButton(video: HTMLVideoElement) {
    const parent = video.parentElement;
    if (isVideoPlaying(video)) {
        parent?.classList.remove('lgt-video-playing');
        video.pause()
    } else {
        parent?.classList.add('lgt-video-playing');
        video.play();
    }
}

export function isVideoPlaying(video: HTMLVideoElement) {
    return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
}
