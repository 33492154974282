<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { PrismicRichText } from '@prismicio/svelte';

	export let slice: Content.HtmlTableSlice;
</script>

<section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		<div class="table-responsive">
			<table class="table table-bordered">
				{#each slice.primary.table_rows as row}
					<tr>
						{#if row.header_row}
							<th><PrismicRichText field={row.table_column_one} /></th>
							<th><PrismicRichText field={row.table_column_two} /></th>
							<th><PrismicRichText field={row.table_column_three} /></th>
							{#if slice.variation === 'default'}
								<th><PrismicRichText field={row.table_column_four} /></th>
							{/if}
						{:else}
							<td><PrismicRichText field={row.table_column_one} /></td>
							<td><PrismicRichText field={row.table_column_two} /></td>
							<td><PrismicRichText field={row.table_column_three} /></td>
							{#if slice.variation === 'default'}
								<td><PrismicRichText field={row.table_column_four} /></td>
							{/if}
						{/if}
					</tr>
				{/each}
			</table>
		</div>
	</div>
</section>
