<script lang="ts">
	import type { Content } from '@prismicio/client';
	import MixedMediaDisplay from '$lib/components/MixedMediaDisplay.svelte';

	export let slice: Content.TiledMixedMediaSlice;
	let columnNumber = (slice.primary.number_of_columns) ? (12 / slice.primary.number_of_columns) : 6;
	let columnGap = (slice.primary.column_gap) ? slice.primary.column_gap : 3;
</script>

<section class="tiled-media" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		{#if slice.primary.title}
			<h4 class="tiled-images__title">{slice.primary.title}</h4>
		{/if}

		<div class="row g-{columnGap}">
			{#each slice.primary.media_items as item }
				<div class="col-12 col-lg-{columnNumber}">
					<MixedMediaDisplay media={item.media} classes="img-fluid" />
				</div>
			{/each}
		</div>
	</div>
</section>
