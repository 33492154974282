<script lang="ts">
	import { Slideshow } from '$lib/helpers/app.slideshow';
	import { PrismicImage } from '@prismicio/svelte';
	import { afterUpdate } from 'svelte';

    export let slice;
	let showArrows = (slice.primary.show_arrows) ? 1 : 0;
	let showCounter = (slice.primary.show_counter) ? 1 : 0;
	let mobileSlides = slice.primary.mobile_slides ?? 1;
	let desktopSlides = slice.primary.desktop_slides ?? 2;
	let hdSlides = (slice.variation != 'bigSlideshow') ? (desktopSlides + 1) : desktopSlides;
    let offset = (slice.primary.offset) ? 200 : 0;

    let slideshowElement: HTMLElement;

    afterUpdate(() => {
        if (slideshowElement) {
            new Slideshow(slideshowElement);
        }
    });
</script>

<div
    data-mobile={mobileSlides}
    data-desktop={desktopSlides}
    data-hd={hdSlides}
    data-offset={offset}
    data-speed="0"
    data-autoplay="0"
    data-arrows={showArrows}
    data-counter={showCounter}
    bind:this={slideshowElement}
>
    <ul id="Slider-{slice.id}" class="component-slideshow__list {slice.variation}">
        {#each slice.primary.images as item,index}
            <li id="Slide-{slice.id}-{index}" class="component-slideshow__slide {slice.variation}">
                <div class="component-slideshow__slide--image-container {slice.variation}">
                    <PrismicImage
                        field={item.image}
                        class="component-slideshow__slide--image"
                        loading="lazy"
                    />

                    {@html `
                        <script type="application/ld+json">
                            {
                                "@context": "https://schema.org/",
                                "@type": "ImageObject",
                                "contentUrl": "${item.image.url}",
                                "creditText": "${item.image.alt}",
                                "creator": {
                                    "@type": "Organization",
                                    "name": "Limegreentangerine",
                                    "url": "https://www.limegreentangerine.co.uk"
                                },
                                "copyrightNotice": "&copy; Limegreentangerine"
                            }
                        </script>
                    `}
                </div>
            </li>
        {/each}
    </ul>

    <div class="component-slideshow__button--container">
        <button type="button" name="previous" class="component-slideshow__button prev" aria-label="Slideshow Previous">
            <i class="bi bi-chevron-left"></i>
        </button>

        <button type="button" name="next" class="component-slideshow__button next" aria-label="Slideshow Next">
            <i class="bi bi-chevron-right"></i>
        </button>
    </div>

    <div class="component-slideshow__counter--container">
        <div class="component-slideshow__counter--items">
            <span id="CurrentSlide-{slice.id}">0</span>
            <span>/</span>
            <span id="TotalSlides-{slice.id}">0</span>
        </div>
    </div>
</div>
