<script lang="ts">
	import type { Content } from '@prismicio/client';
	import * as prismic from '@prismicio/client';
	import { PrismicImage, PrismicRichText } from '@prismicio/svelte';

	export let slice: Content.OfficeLocationSlice;

	let offices = slice.primary.offices ?? false;
</script>

{#if offices}
	<section class="office-locations" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
		<div class="container">
			<div class="row">
				{#each offices as item}
					<div class="col-12 col-lg-6 mb-5 mb-lg-0">
						<div class="office-locations__item">
							<div class="office-locations__item--image-container card-image-placeholder">
								{#if prismic.isFilled.image(item.image)}
									<PrismicImage
										field={item.image}
										class="office-locations__item--image"
										loading="lazy"
									/>
								{/if}
							</div>

							{#if item.map_location}
								<a href="https://www.google.com/maps/@{item.map_location.latitude},{item.map_location.longitude},17z" target="blank">
									<address class="office-locations__item--address">
										<PrismicRichText field={item.address} />
									</address>
								</a>
							{:else}
								<address class="office-locations__item--address">
									<PrismicRichText field={item.address} />
								</address>
							{/if}

							{#if item.phone_number}
								<a href="tel:{item.phone_number}" class="office-locations__item--phone">
									{item.phone_number}
								</a>
							{/if}

							{#if item.email_address}
								<a href="mailto:{item.email_address}" class="office-locations__item--email">
									{item.email_address}
								</a>
							{/if}
						</div>
					</div>
				{/each}
			</div>
		</div>
	</section>
{/if}
