<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { PrismicRichText } from '@prismicio/svelte';

	export let slice: Content.ContentSideTitleSlice;

	let titleColumnWidth = (slice.variation !== 'reasons' && slice.primary.title_width) ? slice.primary.title_width : 4;
	let contentColumnWidth = (slice.variation !== 'reasons' && slice.primary.content_width) ? slice.primary.content_width : 7;
	let verticalAlignment = (slice.variation == 'muted') ? 'align-items-center' : 'align-items-start';
</script>

<section class="content-side-title content-side-title__{slice.variation}" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		{#if slice.variation == 'reasons'}
			<div class="row justify-content-between {verticalAlignment}">
				<div class="col-12 col-lg-4">
					<h4 class="content-side-title__title">
						({String(slice.primary.number).padStart(2, '0')})
					</h4>
				</div>

				<div class="col-12 col-lg-4">
					<h3 class="content-side-title__title">{slice.primary.title}</h3>
				</div>

				<div class="col-12 col-lg-4">
					<div class="content-side-title__content">
						<PrismicRichText field={slice.primary.content} />
					</div>
				</div>
			</div>
		{:else}
			<div class="content-side-title__wrapper">
				<div class="row justify-content-between {verticalAlignment}">
					<div class="col-12 col-lg-{titleColumnWidth}">
						<h3 class="content-side-title__title">{slice.primary.title}</h3>
					</div>

					<div class="col-12 col-lg-{contentColumnWidth}">
						<div class="content-side-title__content">
							<PrismicRichText field={slice.primary.content} />
						</div>
					</div>
				</div>
			</div>
		{/if}
	</div>
</section>
