<script lang="ts">
	import { publicationDateToISO } from "$lib/helpers/app.helpers";

    export let field;
    export let poster;
    export let classes;

    // Ensure videoField has a valid URL
    let videoUrl = field?.url || '';
    let posterUrl = (poster !== '') ? poster.url : '';

    // Optionally, additional attributes (autoplay, controls, etc.) can be passed
    export let autoplay = false;
    export let loop = false;
    export let muted = false;
    export let controls = false;
</script>

{#if videoUrl}
    <video playsinline {autoplay} {loop} {controls} {muted} class={classes} poster={posterUrl} preload="auto">
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
    </video>

    {@html `
        <script type="application/ld+json">
            {
                "@context": "https://schema.org/",
                "@type": "VideoObject",
                "name": "${field.name}",
                "thumbnailUrl": [
                    "${posterUrl}"
                ],
                "uploadDate": "${publicationDateToISO(new Date())}",
                "contentUrl": "${videoUrl}"
            }
        </script>
    `}
{/if}
