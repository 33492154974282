<script lang="ts">
	import PrismicVideo from '$lib/components/PrismicVideo.svelte';
	import * as prismic from '@prismicio/client';
	import type { Content } from '@prismicio/client';
	import { PrismicImage, PrismicRichText } from '@prismicio/svelte';

	export let slice: Content.DesktopScreensSlice;

	const desktopScreens = (slice.primary.desktop_screens.length) ? slice.primary.desktop_screens : false;
	const contentOrder = (slice.primary.content_arrangement == 'Image > Content') ? 'flex-lg-row-reverse' : '';
	const contentWidth = slice.primary.content_width || 6;
	const imageWidth = slice.primary.image_width || 6;
</script>


<section class="desktop-screens" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		<div class="row justify-content-{slice.primary.content_spacing.toLowerCase()} align-items-stretch {contentOrder}">
			<div class="col-12 col-lg-{contentWidth}">
				<div class="position-sticky">
					{#if slice.primary.subtitle}
						<span class="desktop-screens__subtitle">{slice.primary.subtitle}</span>
					{/if}

					{#if slice.primary.title}
						<h3 class="desktop-screens__title">{slice.primary.title}</h3>
					{/if}

					{#if prismic.isFilled.richText(slice.primary.content)}
						<div class="desktop-screens__content">
							<PrismicRichText field={slice.primary.content} />
						</div>
					{/if}
				</div>
			</div>

			<div class="col-12 col-lg-{imageWidth} desktop-screens__image-column">
				{#if desktopScreens}
					<div class="row">
						{#each desktopScreens as item}
							<div class="col-12 mb-3 mb-lg-5">
								<div class="desktop-screens__screen">
									<div class="spinner-grow spinner-grow-sm text-dark" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>

									{#if prismic.isFilled.linkToMedia(item.video)}
										<PrismicVideo
											field={item.video}
											poster={item.image}
											classes="desktop-screens__screen--media"
											autoplay
											loop
											muted
										/>
									{:else if prismic.isFilled.image(item.image)}
										<PrismicImage
											field={item.image}
											class="desktop-screens__screen--media"
											loading="lazy"
										/>
									{/if}
								</div>
							</div>
						{/each}
					</div>
				{/if}
			</div>
		</div>
	</div>
</section>
