<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { inview } from 'svelte-inview';
	import { ColorTrigger, TriggerLastColor } from '$lib/helpers/color.trigger.js';

	export let slice: Content.ColorTriggerSlice;
	let backgroundColor = slice.primary.background_color ?? '--bs-body-bg';
	let foregroundColor = slice.primary.foreground_color ?? '--bs-body-color';
</script>

<section class="color-trigger__section" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="color-trigger__point"
		style="--indicatorBackground:{backgroundColor};--indicatorForeground:{foregroundColor};"
		use:inview
		on:inview_enter={(event) => {
			const { scrollDirection, node } = event.detail;
			ColorTrigger(node, scrollDirection);
		}}
		on:inview_leave={(event) => {
			const { scrollDirection } = event.detail;
			TriggerLastColor(scrollDirection)
		}}
		data-background="{backgroundColor}"
		data-foreground="{foregroundColor}"
	></div>
</section>
