<script lang="ts">
	import PrismicVideo from '$lib/components/PrismicVideo.svelte';
import * as prismic from '@prismicio/client';
	import type { Content } from '@prismicio/client';
	import { PrismicRichText } from '@prismicio/svelte';

	export let slice: Content.ContentSideVideoSlice;
	let imageColumnWidth = slice.primary.video_column_width ?? 6;
	let textColumnWidth = slice.primary.text_column_width ?? 6;
	let mobileArrangement = (slice.primary.mobile_arrangement == 'Video-Content') ? 'flex-row' : 'flex-row-reverse';
	let desktopArrangement = (slice.primary.desktop_arrangement == 'Video-Content') ? 'flex-lg-row' : 'flex-lg-row-reverse';
	let verticalAlignment = slice.primary.vertical_alignment.toLowerCase();
</script>


<section class="content-side-video" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		<div class="row {mobileArrangement} {desktopArrangement} justify-content-between align-items-{verticalAlignment}">
			<div class="col-12 col-lg-{imageColumnWidth}">
				{#if prismic.isFilled.linkToMedia(slice.primary.video)}
					<PrismicVideo
						field={slice.primary.video}
						poster
						classes="img-fluid"
						autoplay
						muted
						loop
					/>
				{/if}
			</div>
			<div class="col-12 col-lg-{textColumnWidth}">
				{#if slice.primary.title }
					<h4 class="content-side-video__title">{slice.primary.title}</h4>
				{/if}

				{#if prismic.isFilled.richText(slice.primary.content)}
					<div class="content-side-video__text">
						<PrismicRichText field={slice.primary.content} />
					</div>
				{/if}
			</div>
		</div>
	</div>
</section>
