<script lang="ts">
	import { type Content } from '@prismicio/client';
	import { PrismicImage } from '@prismicio/svelte';

	export let slice: Content.TiledImagesSlice;
	let columnNumber = (slice.primary.number_of_columns) ? (12 / slice.primary.number_of_columns) : 6;
	let columnGap = (slice.primary.column_gap) ? slice.primary.column_gap : 3;
</script>

<section class="tiled-images" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		{#if slice.primary.title}
			<h4 class="tiled-images__title">{slice.primary.title}</h4>
		{/if}

		<div class="row g-{columnGap}">
			{#each slice.primary.images as image }
				<div class="col-12 col-lg-{columnNumber}">
					<PrismicImage
						field={image.image}
						class="img-fluid"
						loading="lazy"
					/>

					{@html `
						<script type="application/ld+json">
							{
								"@context": "https://schema.org/",
								"@type": "ImageObject",
								"contentUrl": "${image.image.url}",
								"creditText": "${image.image.alt}",
								"creator": {
									"@type": "Organization",
									"name": "Limegreentangerine",
									"url": "https://www.limegreentangerine.co.uk"
								},
								"copyrightNotice": "&copy; Limegreentangerine"
							}
						</script>
					`}
				</div>
			{/each}
		</div>
	</div>
</section>
