<script lang="ts">
    import * as prismic from '@prismicio/client';
    import { PrismicImage } from '@prismicio/svelte';
	import PrismicVideo from '$lib/components/PrismicVideo.svelte';
    import { onMount } from 'svelte';
    import type { LinkToMediaField, FilledImageFieldImage } from '@prismicio/types';

    export let media: LinkToMediaField | null = null;
    export let classes = "";

    let image: FilledImageFieldImage | null | undefined;

    onMount(() => {
        if (prismic.isFilled.linkToMedia(media) && media.kind == 'image') {
            let width: number = media.width ? parseInt(media.width) : 800;
            let height: number = (media.height) ? parseInt(media.height) : 800;

            image = {
                id: media.id,
                edit: {
                    background: 'transparent',
                    x: 0,
                    y: 0,
                    zoom: 1
                },
                copyright: null,
                url: media.url,
                alt: media.name || '',
                dimensions: {
                    width: width,
                    height: height,
                }
            };
        }
    });
</script>

{#if prismic.isFilled.linkToMedia(media) && image !== undefined && image !== null}
    <PrismicImage
        field={image}
        class={classes}
        loading="lazy"
    />

    {@html `
        <script type="application/ld+json">
            {
                "@context": "https://schema.org/",
                "@type": "ImageObject",
                "contentUrl": "${image.url}",
                "creditText": "${image.alt}",
                "creator": {
                    "@type": "Organization",
                    "name": "Limegreentangerine",
                    "url": "https://www.limegreentangerine.co.uk"
                },
                "copyrightNotice": "&copy; Limegreentangerine"
            }
        </script>
    `}
{:else if prismic.isFilled.linkToMedia(media)}
    <PrismicVideo
        field={media}
        poster=""
        classes={classes}
        autoplay
        loop
        muted
    />
{/if}
