<script lang="ts">
	import type { Content } from '@prismicio/client';

	export let slice: Content.StatsSlice;
</script>

<section class="stats" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		<div class="row justify-content-between">
			<div class="col-12 col-lg-4">
				<div class="stats__sticky">
					<h4 class="stats__title">{slice.primary.title}</h4>
				</div>
			</div>

			<div class="col-12 col-lg-8">
				{#each slice.primary.stats as stat}
					<div class="stats__item">
						<div class="stats__item--value">{stat.value}</div>
						<div class="stats__item--description">{stat.description}</div>
					</div>
				{/each}
			</div>
		</div>
	</div>
</section>
