<script lang="ts">
	import ClientSlideshow from '$lib/components/ClientSlideshow.svelte';
import type { Content } from '@prismicio/client';

	export let slice: Content.ClientsSlice;
</script>

<section class="clients" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		<h3 class="clients__title">{slice.primary.title}</h3>
	</div>

	<ClientSlideshow slice={slice} />
</section>
