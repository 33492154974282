// Code generated by Slice Machine. DO NOT EDIT.

import Clients from './Clients/index.svelte';
import ColorTrigger from './ColorTrigger/index.svelte';
import ContentSideImage from './ContentSideImage/index.svelte';
import ContentSideTitle from './ContentSideTitle/index.svelte';
import ContentSideVideo from './ContentSideVideo/index.svelte';
import DesktopScreens from './DesktopScreens/index.svelte';
import FeaturedProjects from './FeaturedProjects/index.svelte';
import HorizontalLine from './HorizontalLine/index.svelte';
import HtmlTable from './HtmlTable/index.svelte';
import Image from './Image/index.svelte';
import ImageCarousel from './ImageCarousel/index.svelte';
import MobileScreens from './MobileScreens/index.svelte';
import OfficeLocation from './OfficeLocation/index.svelte';
import ProjectSlideshow from './ProjectSlideshow/index.svelte';
import Quote from './Quote/index.svelte';
import RelatedProjects from './RelatedProjects/index.svelte';
import RichText from './RichText/index.svelte';
import SectionTitle from './SectionTitle/index.svelte';
import ServiceInformation from './ServiceInformation/index.svelte';
import Spacer from './Spacer/index.svelte';
import StaffDisplay from './StaffDisplay/index.svelte';
import Stats from './Stats/index.svelte';
import TiledImages from './TiledImages/index.svelte';
import TiledMixedMedia from './TiledMixedMedia/index.svelte';
import TiledVideo from './TiledVideo/index.svelte';
import Video from './Video/index.svelte';

export const components = {
	clients: Clients,
	color_trigger: ColorTrigger,
	content_side_image: ContentSideImage,
	content_side_title: ContentSideTitle,
	content_side_video: ContentSideVideo,
	desktop_screens: DesktopScreens,
	featured_projects: FeaturedProjects,
	horizontal_line: HorizontalLine,
	html_table: HtmlTable,
	image: Image,
	image_carousel: ImageCarousel,
	mobile_screens: MobileScreens,
	office_location: OfficeLocation,
	project_slideshow: ProjectSlideshow,
	quote: Quote,
	related_projects: RelatedProjects,
	rich_text: RichText,
	section_title: SectionTitle,
	service_information: ServiceInformation,
	spacer: Spacer,
	staff_display: StaffDisplay,
	stats: Stats,
	tiled_images: TiledImages,
	tiled_mixed_media: TiledMixedMedia,
	tiled_video: TiledVideo,
	video: Video
};
