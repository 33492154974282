<script lang="ts">
	import MixedMediaDisplay from '$lib/components/MixedMediaDisplay.svelte';
	import * as prismic from '@prismicio/client';
	import type { Content } from '@prismicio/client';
	import { PrismicRichText } from '@prismicio/svelte';
	import { slide } from 'svelte/transition';

	export let slice: Content.ServiceInformationSlice;
</script>

<section class="service-information" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		{#if prismic.isFilled.richText(slice.primary.title)}
			<div class="row">
				<div class="col-12 col-lg-7 order-2 order-lg-1">
					<div class="service-information__title">
						<PrismicRichText field={slice.primary.title} />
					</div>
				</div>
			</div>
		{/if}

		<div class="row">
			<div class="col-12 col-lg-5 order-1 order-lg-3 mb-3 mb-lg-0">
				<div class="service-information__image--container card-image-placeholder">
					<MixedMediaDisplay media={slice.primary.media} classes="service-information__image" />
				</div>
			</div>

			{#if prismic.isFilled.richText(slice.primary.description)}
				<div class="col-12 col-lg-4 order-2 order-lg-1">
					<div class="service-information__description pe-lg-5">
						<PrismicRichText field={slice.primary.description} />
					</div>
				</div>
			{/if}

			{#if slice.primary.service_types.length}
				<div class="col-12 col-lg-3 order-2 order-lg-2">
					<ul class="service-information__list">
						{#each slice.primary.service_types as type}
							<li>{type.name}</li>
						{/each}
					</ul>
				</div>
			{/if}
		</div>
	</div>
</section>
