<script lang="ts">
	import type { Content } from '@prismicio/client';

	export let slice: Content.HorizontalLineSlice;
	let lineColor = slice.primary.line_color ?? '--bs-gray-400';
	let columnWidth = (slice.variation == 'grid' && slice.primary.column_width) ? slice.primary.column_width : 12;
</script>

<section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	{#if slice.variation == 'grid'}
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-lg-{columnWidth}">
					<hr class="m-0" style="--lineColor:{lineColor};" />
				</div>
			</div>
		</div>
	{:else if slice.variation == 'container'}
		<div class="container">
			<hr class="m-0" style="--lineColor:{lineColor}; !important" />
		</div>
	{:else}
		<hr class="m-0" style="--lineColor:{lineColor}; !important" />
	{/if}
</section>
