<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { inview } from 'svelte-inview';
	import { PlayVideoOnScroll, PauseVideoOnScroll, handleVideoPlay } from '$lib/video.player';
	import { PrismicImage } from '@prismicio/svelte';
	import { publicationDateToISO } from '$lib/helpers/app.helpers';

	export let slice: Content.VideoSlice;

	let video: any = slice.primary.video ?? false;
	let autoplay: boolean = slice.primary.autoplay;
	let loop: boolean = slice.primary.loop;
	let muted: boolean = slice.primary.muted ?? false;
	let controls: boolean = slice.primary.show_controls ?? false;
	let buttonColor: string = slice.primary.button_color ?? '--bs-primary';
	const inViewOptions = {
		rootMargin: '-40%'
	};
</script>

<section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	{#if video}
		{#if slice.variation == 'container'}
			<div class="container">
				<section class="lgt-video__container">
					<!-- svelte-ignore a11y-media-has-caption -->
					<video
						use:inview={inViewOptions}
						on:inview_enter={(event) => {
							const { node } = event.detail;
							if (node.classList.contains('lgt-video-autoplay')) {
								PlayVideoOnScroll(node);
							}
						}}
						on:inview_leave={(event) => {
							const { node } = event.detail;
							PauseVideoOnScroll(node);
						}}
						id="{slice.id}"
						class="lgt-video"
						class:lgt-video-autoplay={autoplay}
						class:lgt-video-controls={controls}
						poster="{slice.primary.poster.url}"
						preload="auto"
						playsinline
						{autoplay}
						{loop}
						{muted}
					>
						<source src="{video.url}" type="video/mp4" />
					</video>

					{@html `
						<script type="application/ld+json">
							{
								"@context": "https://schema.org/",
								"@type": "VideoObject",
								"name": "${video.name}",
								"thumbnailUrl": [
									"${slice.primary.poster.url}"
								],
								"uploadDate": "${publicationDateToISO(new Date())}",
								"contentUrl": "${video.url}"
							}
						</script>
					`}

					{#if !slice.primary.autoplay }
						<PrismicImage
							field={slice.primary.poster}
							class="lgt-video__poster"
							loading="lazy"
						/>
					{/if}

					{#if slice.primary.show_controls }
						<!-- svelte-ignore a11y-click-events-have-key-events -->
						<!-- svelte-ignore a11y-unknown-aria-attribute -->
						<!-- svelte-ignore a11y-no-static-element-interactions -->
						<div class="lgt-video__controls" id="lgt-video-play__{slice.id}" data-target="{slice.id}" on:click={handleVideoPlay} aria-controls="Play Video">
							<div class="lgt-video__play" style="--lgtVideoPlayButtonColor:{buttonColor};">
								<i class="bi bi-play-fill"></i>
							</div>
						</div>
					{/if}
				</section>
			</div>
		{:else}
			<section class="lgt-video__container">
				<!-- svelte-ignore a11y-media-has-caption -->
				<video
					use:inview={inViewOptions}
					on:inview_enter={(event) => {
						const { node } = event.detail;
						if (node.classList.contains('lgt-video-autoplay')) {
							PlayVideoOnScroll(node);
						}
					}}
					on:inview_leave={(event) => {
						const { node } = event.detail;
						PauseVideoOnScroll(node);
					}}
					id="{slice.id}"
					class="lgt-video"
					class:lgt-video-autoplay={autoplay}
					class:lgt-video-controls={controls}
					poster="{slice.primary.poster.url}"
					preload="auto"
					playsinline
					{autoplay}
					{loop}
					{muted}
				>
					<source src="{video.url}" type="video/mp4" />
				</video>

				{@html `
					<script type="application/ld+json">
						{
							"@context": "https://schema.org/",
							"@type": "VideoObject",
							"name": "${video.name}",
							"thumbnailUrl": [
								"${slice.primary.poster.url}"
							],
							"uploadDate": "${publicationDateToISO(new Date())}",
							"contentUrl": "${video.url}"
						}
					</script>
				`}

				{#if !slice.primary.autoplay }
					<PrismicImage
						field={slice.primary.poster}
						class="lgt-video__poster"
						loading="lazy"
					/>
				{/if}

				{#if slice.primary.show_controls }
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<!-- svelte-ignore a11y-unknown-aria-attribute -->
					<!-- svelte-ignore a11y-no-static-element-interactions -->
					<div class="lgt-video__controls" id="lgt-video-play__{slice.id}" data-target="{slice.id}" on:click={handleVideoPlay} aria-controls="Play Video">
						<div class="lgt-video__play" style="--lgtVideoPlayButtonColor:{buttonColor};">
							<i class="bi bi-play-fill"></i>
						</div>
					</div>
				{/if}
			</section>
		{/if}
	{/if}
</section>
