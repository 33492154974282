<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { onMount } from 'svelte';
	import { inview } from 'svelte-inview';

	export let slice: Content.SectionTitleSlice;

	let target: HTMLElement;
	let name: HTMLElement;

	onMount(() => {
		target = document.querySelector('.section-title-display') as HTMLElement;
		name = target.querySelector('.section-title-display__name') as HTMLElement;
	});
</script>

<section
	use:inview={{
		rootMargin: '-10%'
	}}
	on:inview_enter={(event) => {
		const { node, scrollDirection } = event.detail;
		document.body.classList.remove('show-section-title');

		if (scrollDirection.vertical == 'up' && target) {
			const indexName = node.dataset.name ?? false;

			setTimeout(() => {
				if (indexName) {
					name.innerText = indexName;
					document.body.classList.add('show-section-title');
				} else {
					document.body.classList.remove('show-section-title');
				}
			}, 600);
		}
	}}
	class="section-title"
	data-slice-type={slice.slice_type}
	data-slice-variation={slice.variation}
	data-name={slice.primary.name}
></section>
