<script lang="ts">
	import type { Content } from '@prismicio/client';
	import { PrismicImage } from '@prismicio/svelte';

	export let slice: Content.ImageSlice;
</script>

{@html `
<script type="application/ld+json">
    {
		"@context": "https://schema.org/",
		"@type": "ImageObject",
		"contentUrl": "${slice.primary.image.url}",
		"creditText": "${slice.primary.image.alt}",
		"creator": {
			"@type": "Organization",
			"name": "Limegreentangerine",
			"url": "https://www.limegreentangerine.co.uk"
		},
		"copyrightNotice": "&copy; Limegreentangerine"
    }
</script>
`}

<section class="image" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	{#if slice.variation !== 'fullWidth'}
		<div class="container">
			<PrismicImage
				field={slice.primary.image}
				class="img-fluid"
    			loading="lazy"
			/>
		</div>
	{:else}
		<PrismicImage
			field={slice.primary.image}
			class="img-fluid"
			loading="lazy"
		/>
	{/if}
</section>
