import * as prismic from '@prismicio/client';
import { createClient } from "$lib/prismicio";
import type { BuildQueryURLArgs } from "@prismicio/client";
import type { BlogDocument, WorkDocument } from "../../prismicio-types";

export type RedirectType = {
    old: string;
    new: string;
    code: number;
}

export const getPaginatedDocument = async (
    type: string,
    itemsPerPage: number,
    pageNum: number,
    nextPageAvailable: boolean,
    filter: string | boolean = false
) => {
    if (!nextPageAvailable) return;

    const client = createClient();
    let results: BlogDocument[] | WorkDocument[] = [];
    let nextPage = null;
    const options: Partial<BuildQueryURLArgs> = {
        pageSize: itemsPerPage,
        page: pageNum
    };

    if (type == 'work') {
        options.orderings = [
            {
                field: 'my.work.sort_order',
                direction: 'desc'
            },
            {
                field: 'my.work.publication_date',
                direction: 'desc'
            }
        ];
    } else {
        options.orderings = [
            {
                field: 'my.blog.publication_date',
                direction: 'desc'
            }
        ];
    }

    if (type !== 'blog') {
        if (filter !== false) {
            filter = String(filter);
            options.filters = [
                prismic.filter.at('document.tags', [filter])
            ];
        } else {
            options.filters = [
                prismic.filter.not('document.tags', ['archive'])
            ];
        }
    } else if (filter !== false) {
        let filterString = '';
        switch(filter) {
            case 'press':
                filterString = 'Press';
                break;
            case 'project':
                filterString = 'Project Updates';
                break;
            case 'studio':
                filterString = 'Studio Insights';
                break;
        }

        if (filterString.length > 0) {
            options.filters = [
                prismic.filter.at('my.blog.type', filterString)
            ];
        }
    }

    const documentType = type as "work" | "home" | "portfolio" | "contact" | "page" | "global_nav" | "project_navigation" | "site_footer" | "staff_member" | "insights" | "blog";
    const documents = await client.getByType(documentType, options);

    if (documents) {
        results = documents.results as BlogDocument[] | WorkDocument[]
        nextPage = documents.next_page;

        return {
            results,
            nextPage,
            pageNum
        }
    }
};

export function combinePageArrays<T>(...arrays: T[][]): T[] {
    return arrays.flat();
}


export const getStaffMembers = async () => {
    const client = createClient();
    const options: Partial<BuildQueryURLArgs> = {
        orderings: [
            {
                field: 'document.first_publication_date',
                direction: 'asc'
            }
        ]
    };
    const documents = await client.getAllByType('staff_member', options);

    if (documents) {
        return documents;
    }
};

export const getRelated = async (items: number, current: string) => {
    const client = createClient();
    const options: Partial<BuildQueryURLArgs> = {
        pageSize: items,
        page: 1,
        filters: [
            prismic.filter.not('document.id', current)
        ],
        orderings: [
            {
                field: 'my.blog.publication_date',
                direction: 'desc'
            }
        ]
    };

    const documents = await client.getByType('blog', options);

    if (documents) {
        const results = documents.results as BlogDocument[];
        return results;
    }
}
