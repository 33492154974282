<script lang="ts">
	import { Slideshow } from '$lib/helpers/app.slideshow';
	import { PrismicImage, PrismicRichText, PrismicLink } from '@prismicio/svelte';
	import { afterUpdate } from 'svelte';

    export let slice;

	let showArrows = 0;
	let showCounter = 0;
	let mobileSlides = 1;
	let desktopSlides = 2;
	let hdSlides = (desktopSlides + 1);
    let offset = 200;

    let slideshowElement: HTMLElement;

    afterUpdate(() => {
        if (slideshowElement) {
            new Slideshow(slideshowElement);
        }
    });
</script>

<div
    data-mobile={mobileSlides}
    data-desktop={desktopSlides}
    data-hd={hdSlides}
    data-offset={offset}
    data-speed="0"
    data-autoplay="0"
    data-arrows={showArrows}
    data-counter={showCounter}
    bind:this={slideshowElement}
>
    <ul id="Slider-{slice.id}" class="component-slideshow__list">
        {#each slice.primary.clients as item,index}
            <li id="Slide-{slice.id}-{index}" class="component-slideshow__slide">
                <PrismicLink field={item.link} class="clients__item ps-2 pe-2 ps-lg-0 pe-lg-0">
                    <div class="clients__item--image-container">
                        <PrismicImage
                            field={item.logo}
                            class="clients__item--image"
                            loading="lazy"
                        />
                    </div>

                    <div class="clients__item--content">
                        <h5 class="clients__item--content-title">{item.name}</h5>
                        <div class="clients__item--content-description">
                            <PrismicRichText field={item.description} />
                        </div>
                    </div>
                </PrismicLink>
            </li>
        {/each}
    </ul>

    <div class="component-slideshow__button--container">
        <button type="button" name="previous" class="component-slideshow__button prev" aria-label="Slideshow Previous">
            Prev
        </button>

        <button type="button" name="next" class="component-slideshow__button next" aria-label="Slideshow Next">
            Next
        </button>
    </div>

    <div class="component-slideshow__counter--container">
        <div class="component-slideshow__counter--items">
            <span id="CurrentSlide-{slice.id}">0</span>
            <span>/</span>
            <span id="TotalSlides-{slice.id}">0</span>
        </div>
    </div>
</div>
