<script lang="ts">
	import type { Content } from '@prismicio/client';
	import ImageSlideshow from '$lib/components/ImageSlideshow.svelte';

	export let slice: Content.ImageCarouselSlice;
</script>

<section class="image-carousel" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<ImageSlideshow slice={slice} />
</section>
