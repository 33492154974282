<script lang="ts">
	import ProjectCard from '$lib/components/ProjectCard.svelte';
	import type { Content } from '@prismicio/client';

	export let slice: Content.FeaturedProjectsSlice;

	const flexArrangment = (slice.primary.feature_arrangement == 'Left') ? 'flex-lg-row' : 'flex-lg-row-reverse';
	const feature = slice.primary.projects[0] ?? false;
</script>

{#if feature}
	<section class="featured-projects" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
		<div class="container">
			<div class="row {flexArrangment} align-items-stretch gy-1 gx-2 g-lg-3">
				<div class="col-12 col-lg-6">
					<ProjectCard
						title={feature.title}
						project={feature.project}
						video={feature.video}
						image={feature.image}
						baseClass="featured-projects__primary"
					/>
				</div>

				<div class="col-12 col-lg-6">
					<div class="row align-items-stretch gy-1 gx-2 g-lg-3">
						{#each slice.primary.projects as item,index}
							{#if index > 0 && index <= 4}
								<div class="col-6">
									<ProjectCard
										index={index}
										title={item.title}
										project={item.project}
										video={item.video}
										image={item.image}
										baseClass="featured-projects__item"
									/>
								</div>
							{/if}
						{/each}
					</div>
				</div>
			</div>
		</div>
	</section>
{/if}
