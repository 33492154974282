<script lang="ts">
	import { getStaffMembers } from '$lib/helpers/app.documents';
	import type { Content } from '@prismicio/client';
	import { PrismicImage, PrismicRichText } from '@prismicio/svelte';
	import { onMount } from 'svelte';
	import { fade, type FadeParams } from 'svelte/transition';
	import type { StaffMemberDocument } from '../../../prismicio-types';

	export let slice: Content.StaffDisplaySlice;

	const mobileColumns = (slice.primary.mobile_columns) ? (12 / slice.primary.mobile_columns) : 12;
	const desktopColumns = (slice.primary.desktop_columns) ? (12 / slice.primary.desktop_columns) : 12;
	const hdColumns = (slice.primary.desktop_columns) ? (12 / (slice.primary.desktop_columns + 1)) : 12;
	const animateDuration: number = 400;
	const fadeOptions: FadeParams = {
		duration: animateDuration
	}

	let staff: StaffMemberDocument[] | undefined;

	onMount(async () => {
		staff = await getStaffMembers();
	});
</script>

<section class="staff-display" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-4">
				<h4 class="staff-display__title">{slice.primary.title}</h4>
			</div>

			<div class="col-12 col-lg-8">
				<div class="staff-display__description">
					<PrismicRichText field={slice.primary.description} />
				</div>
			</div>
		</div>

		{#if staff}
			<div class="staff-display__list" transition:fade={fadeOptions}>
				<div class="row gx-3 gy-5">
					{#each staff as item}
						<div class="col-{mobileColumns} col-lg-{desktopColumns} col-hd-{hdColumns}">
							<div class="staff-display__item">
								<div class="staff-display__item--image-container card-image-placeholder">
									<PrismicImage
										field={item.data.image}
										class="staff-display__item--image"
										loading="lazy"
									/>
								</div>

								<div class="staff-display__item--content">
									<h4 class="staff-display__item--name">{item.data.name}</h4>
									<h5 class="staff-display__item--title">{item.data.job_title}</h5>
								</div>
							</div>
						</div>
					{/each}
				</div>
			</div>
		{/if}
	</div>
</section>
