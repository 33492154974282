// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { scrollend } from 'scrollyfills';

export class SlideshowData {
    mobile: number;
    desktop: number;
    hd: number;
    autoplay: boolean;
    speed: number;
    offset: number;
    arrows: boolean;
    counter: boolean;

    constructor(mobile: number, desktop: number, hd: number, autoplay: boolean, speed: number, offset: number, arrows: boolean, counter: boolean) {
        this.mobile = mobile;
        this.desktop = desktop;
        this.hd = hd;
        this.autoplay = autoplay;
        this.speed = speed;
        this.offset = offset;
        this.arrows = arrows;
        this.counter = counter;
    }
}

export class SlideshowStyles {
    mobile: string;
    desktop: string;
    hd: string;

    constructor(mobile: string, desktop: string, hd: string) {
        this.mobile = mobile;
        this.desktop = desktop;
        this.hd = hd;
    }
}

export class Slideshow {
    slideshow: HTMLElement;
    slideshowList: HTMLElement | null;
    slideshowSlides: NodeList;
    buttonContainer: HTMLElement | null;
    currentPageElement: HTMLElement | null;
    pageTotalElement: HTMLElement | null;
    prevButton: HTMLElement | null;
    nextButton: HTMLElement | null;
    counterContainer: HTMLElement | null;
    slideshowData: SlideshowData | undefined;
    slideshowStyles: SlideshowStyles | undefined;
    slideshowClasses: Array<string> | undefined;
    sliderItemsToShow: Array<Node> | undefined;
    sliderItemOffset: number = 0;
    slidesPerPage: number = 0;
    totalPages: number = 0;
    currentPage: number = 0;
    previousPage: number = 0;
    slideScrollPosition: number = 0;
    autoplayInterval: NodeJS.Timeout | undefined;

    constructor(slideshow: HTMLElement) {
        this.slideshow = slideshow;

        // get base elements
        this.slideshowList = this.slideshow.querySelector('[id^=Slider-]');
        this.slideshowSlides = this.slideshow.querySelectorAll('[id^=Slide-]');
        this.buttonContainer = this.slideshow.querySelector('.component-slideshow__button--container');
        this.counterContainer = this.slideshow.querySelector('.component-slideshow__counter--container');

        // get child elements
        this.currentPageElement = (this.counterContainer) ? this.counterContainer.querySelector('[id^="CurrentSlide-"]') : null;
        this.pageTotalElement = (this.counterContainer) ? this.counterContainer.querySelector('[id^="TotalSlides-"]') : null;
        this.prevButton = (this.buttonContainer) ? this.buttonContainer.querySelector('button[name=previous]') : null;
        this.nextButton = (this.buttonContainer) ? this.buttonContainer.querySelector('button[name=next]') : null;

        if (!this.slideshowList || !this.slideshowSlides || !this.prevButton || !this.nextButton) return;

        // define strings
        const mobile: string = this.slideshow.dataset.mobile ?? '0';
        const desktop: string = this.slideshow.dataset.desktop ?? '0';
        const hd: string = this.slideshow.dataset.hd ?? '0';
        const autoplay: string = this.slideshow.dataset.autoplay ?? '0';
        const speed: string = this.slideshow.dataset.speed ?? '0';
        const offset: string = this.slideshow.dataset.offset ?? '0';
        const arrows: string = this.slideshow.dataset.arrows ?? '0';
        const counter: string = this.slideshow.dataset.counter ?? '0';

        // create slideshow data
        this.slideshowData = new SlideshowData(
            parseInt(mobile),
            parseInt(desktop),
            parseInt(hd),
            (parseInt(autoplay) > 0) ? true: false,
            (parseInt(speed) * 1000),
            parseInt(offset),
            (parseInt(arrows) > 0) ? true : false,
            (parseInt(counter) > 0) ? true : false
        );

        // create slide width css variables
        this.slideshowStyles = {
            mobile: this.createStyleString(this.slideshowData.mobile),
            desktop: this.createStyleString(this.slideshowData.desktop),
            hd: this.createStyleString(this.slideshowData.hd)
        }

        // create relevant classes
        this.slideshowClasses = [
            'component-slideshow'
        ];

        if (this.slideshowData.autoplay) this.slideshowClasses.push('component-slideshow__autoplay');
        if (this.slideshowData.arrows) this.slideshowClasses.push('component-slideshow__arrows');
        if (this.slideshowData.counter) this.slideshowClasses.push('component-slideshow__counter');

        // init resize observers
        const resizeObserver = new ResizeObserver(() => this.initSlides());
        resizeObserver.observe(this.slideshow);

        // init slideshow
        this.initSlides();

        // init element listeners
        this.prevButton.addEventListener('click', (event) => this.onButtonClick(event));
        this.nextButton.addEventListener('click', (event) => this.onButtonClick(event));
        this.slideshowList.addEventListener('scrollend', () => this.triggerSlideChangeEvent());
        this.slideshow.addEventListener('slideChanged', () => this.processSlideChangeEvent());
    }

    createStyleString = (value: number): string => {
        const widthCalc = (100 / value);
        return `${widthCalc}%`;
    };

    initSlides() {
        this.sliderItemsToShow = Array.from(this.slideshowSlides).filter((element) => {
            const el = element as HTMLElement;
            return el.clientWidth > 0
        });
        if (this.sliderItemsToShow.length < 2) return;

        const slideIndexZero = this.sliderItemsToShow[0] as HTMLElement;
        const slideIndexOne = this.sliderItemsToShow[1] as HTMLElement;
        this.sliderItemOffset = slideIndexOne.offsetLeft - slideIndexZero.offsetLeft;
        this.slidesPerPage = Math.floor((this.slideshow.clientWidth - slideIndexZero.offsetLeft) / this.sliderItemOffset);
        this.totalPages = this.sliderItemsToShow.length - this.slidesPerPage + 1;

        this.update();

        this.triggerSlideChangeEvent();

        if (this.slideshowData?.autoplay) {
            this.initAutoplay();
        }

        this.setSlidePosition(0);
    }

    update() {
        if (!this.slideshowList || !this.nextButton) return;

        // add relevant classes
        this.slideshowClasses?.forEach((className) => {
            this.slideshow?.classList.add(className);
        });

        // add css variables styles
        if (this.slideshowStyles) {
            Object.entries(this.slideshowStyles).forEach(([key,value]) => {
                this.slideshow?.style.setProperty(`--${key}SlideWidth`, value);
            });
            this.slideshow.style.setProperty(`--offset`, `${String(this.slideshowData?.offset)}px`);
        }
    }

    isSlideVisible(element: Node, offset: number = 0) {
        if (!this.slideshowList) return;
        const el = element as HTMLElement;
        const slideStyle = window.getComputedStyle(this.slideshowList);
        const lastVisibleSlide = this.slideshowList.clientWidth + (this.slideshowList.scrollLeft + parseInt(slideStyle.marginLeft)) - offset;
        return el.offsetLeft + el.clientWidth <= lastVisibleSlide && el.offsetLeft >= this.slideshowList.scrollLeft + parseInt(slideStyle.marginLeft);
    }

    triggerSlideChangeEvent() {
        this.previousPage = this.currentPage;
        this.currentPage = (this.slideshowList) ? Math.round(this.slideshowList.scrollLeft / this.sliderItemOffset) + 1 : 0;

        this.slideshowSlides.forEach((slide) => {
            const slideElement = slide as HTMLElement;
            slideElement.classList.remove('component-slideshow__current');
        })

        const slideElement = this.slideshowSlides[this.currentPage - 1] as HTMLElement;
        if (slideElement) {
            slideElement.classList.add('component-slideshow__current');
        }

        if (this.slideshowData?.autoplay) {
            this.initAutoplay();
        }

        this.slideshow.dispatchEvent(
            new CustomEvent('slideChanged', {
                detail: {
                    currentPage: this.currentPage,
                    currentElement: (this.sliderItemsToShow) ? this.sliderItemsToShow[this.currentPage - 1] : 0,
                },
            })
        );
    }

    onButtonClick(event: MouseEvent) {
        event.preventDefault();
        const target = event.currentTarget as HTMLButtonElement;
        if (!this.slideshowList || !target) return;
        let step: number = 1;

        step = step * this.slidesPerPage;
        this.slideScrollPosition = (target.name === 'next') ? this.slideshowList.scrollLeft + (step * this.sliderItemOffset) : this.slideshowList.scrollLeft - (step * this.sliderItemOffset);

        this.setSlidePosition(this.slideScrollPosition);

        if (this.slideshowData?.autoplay) {
            this.initAutoplay();
        }
    }

    setSlidePosition(position: number) {
        this.slideshowList?.scrollTo({
            left: position,
        });
    }

    processSlideChangeEvent() {
        if (!this.slideshowList || !this.prevButton || !this.nextButton || !this.sliderItemsToShow || !this.currentPageElement || !this.pageTotalElement) return;

        this.currentPageElement.textContent = String(this.currentPage);
        this.pageTotalElement.textContent = String(this.totalPages);

        if (this.isSlideVisible(this.sliderItemsToShow[0]) && this.slideshowList.scrollLeft === 0) {
            this.prevButton.setAttribute('disabled', 'disabled');
        } else {
            this.prevButton.removeAttribute('disabled');
        }


        if (this.isSlideVisible(this.sliderItemsToShow[this.sliderItemsToShow.length - 1])) {
            this.nextButton.setAttribute('disabled', 'disabled');
        } else {
            this.nextButton.removeAttribute('disabled');
        }
    }

    initAutoplay() {
        clearInterval(this.autoplayInterval);
        const currentSlideElement = this.slideshowSlides[this.currentPage - 1] as HTMLElement;
        const slideHold = (currentSlideElement) ? currentSlideElement.dataset.hold : false;
        const autoplayTimeout = (slideHold) ? (parseInt(slideHold) * 1000) : this.slideshowData?.speed;
        this.autoplayInterval = setInterval(() => this.triggerAutoplay(), autoplayTimeout);
    }

    triggerAutoplay() {
        if (!this.nextButton) return;

        if (this.nextButton.hasAttribute('disabled')) {
            this.setSlidePosition(0);
        } else {
            this.nextButton.click();
        }
    }
}
